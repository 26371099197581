<template>
  <div>
    <Necker />
    <div class="container mt-12">
      <el-table
        :data="rows"
        class="w-full shadow rounded"
        header-cell-class-name="text-gray-light font-normal text-sm"
        @row-click="onClick"
      >
        <el-table-column prop="date" label="日期" :min-width="10" width="150" />
        <el-table-column
          prop="text"
          label="標題"
          class="text-primary"
          class-name="text-primary"
          :min-width="70"
        >
          <template #default="scope">
            {{ scope.row.text || '無標題' }}
          </template>
        </el-table-column>
        <el-table-column prop="types" label="標籤" :min-width="20">
          <template #default="scope">
            <div class="flex flex-wrap">
              <div
                v-for="(item, index) in scope.row.types"
                :key="index"
                class="mx-1 rounded-full text-sm text-white bg-info px-3 py-1 my-1"
                :style="{ backgroundColor: item.color }"
              >
                {{ item.text }}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script setup>
import { ElTable, ElTableColumn } from 'element-plus';
import { computed, provide } from 'vue';
import Necker from '@/views/_necker/Index.vue';
import News from '@/api/News.js';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
provide('page', {
  breadcrumbs: ['最新消息'],
  title: '最新消息',
  pcBg: ref('banner04.png'),
  mobileBg: ref('banner04_m.png'),
});
const rows = ref([]);
const fetcher = async () => {
  const res = await News.list();
  if (res.code === 1) {
    rows.value = res.data.rows;
  }
};
const router = useRouter();
const onClick = (row) => {
  router.push({ name: 'News.Show', params: { id: row.id } });
};

fetcher();
</script>

<style lang="sass" scoped></style>
